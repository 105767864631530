document.addEventListener("turbo:load", function() {
  document.querySelector('#metadata_seasons.index') && metadata_seasons.index()
  //document.querySelector('#metadata_seasons.edit') && metadata_seasons.edit()
  document.querySelector('#metadata_seasons.show') && metadata_seasons.show()
});

var metadata_seasons = {

  index() {
    calendar.datepicker(".modified_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".modified_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".creation_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".creation_date_end", { position: 'top right', lastResort: 'top right' } )
  },

  show() {
    $('#metadata_seasons.show .menu .item').tab();
    metadata_seasons.fetch_episode_cards()
  },

  edit() {
    metadata_seasons.init_dropdowns();
    $('.special.card .image').dimmer({on: 'hover'});

    $('#metadata_seasons .with-tooltip').popup();

    $('.ui.accordion.metadata-season-cast').accordion({ animateChildren: false });
    $('.ui.accordion.season-cast-crew-show-page').accordion({ animateChildren: false });

    metadata_seasons.metadata_season_cast()
    metadata_seasons.metadata_season_comment()

    metadata.approval_steps()

    let sidebar_name = "metadata_season_casts"
    general.element_sidebar(sidebar_name)
    metadata.update_sidebar(sidebar_name)

    metadata.casts(".element-sidebar-container." + sidebar_name, sidebar_name)

    $("#metadata_seasons").off("fields_added.nested_form_fields")
    $("#metadata_seasons").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "metadata_season_cast": 
          general.add_sidebar_item($(event.target).find(".nested-fields"), sidebar_name)
          
          //metadata_seasons.init_dropdowns()
          $("#metadata_seasons #season_cast_crew_form .default-options.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });
          metadata.update_sidebar(sidebar_name)

          $(event.target).find(".dropdown.member-type").dropdown("set value", "Cast")
          $(event.target).find(".billing-top").checkbox("set checked")

          metadata.casts($(event.target), sidebar_name)
          return
        case "cast_code":
          metadata_seasons.init_dropdowns()
          return
        case "metadata_season_episode":
          $(event.target).find(".remote.metadata_title.card").data("model-id", param.additional_data.metadata_title.value)
          metadata_seasons.fetch_episode_cards()
          return
        case "metadata_season_territory":
          $("#metadata_seasons #season_territories_form .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})
          metadata_seasons.init_dropdowns()
          return
        case "metadata_season_language":
          metadata_seasons.init_dropdowns()
          $("#metadata_seasons #season_languages_form .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})
          return
        case "metadata_season_comment":
          $("#metadata_seasons #season_comment_form .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
          metadata_seasons.metadata_season_comment();
          return
      default:
        return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#metadata_seasons").off("fields_removed.nested_form_fields")
    $("#metadata_seasons").on("fields_removed.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "metadata_season_cast": 
          general.remove_sidebar_item($(event.target).find(".nested-fields").attr("data-model-id"), sidebar_name)
          return
        case "metadata_image":
        case "metadata_season_territory_image":
          const image_rows = $(event.target).parent('.image-rows')
          let images = image_rows.find('fieldset:visible')
          if (images.length <= 0) {
            image_rows.parent('.segment').find('.no-image.segment').show()
          }
          return
      default:
        return console.log(`Fields were successfully removed, callback not handled for ${param.object_class}.`)
      }
    })

    metadata_seasons.fetch_episode_cards()
    metadata_seasons.title_autocompletion('#season_episodes_form')

    metadata_seasons.export_metadata_modal()
  },

  fetch_episode_cards() {
    Array.from($('#season_episodes_form').find('.remote.metadata_title.card')).map((card) => {
      general.fetch_view(card).then(function(_data) {
        general.quicklook_icon($(card).find(".quicklook"))
        $(card).find(".quicklook").off('click')
        $(card).find(".quicklook").on('click', function() {
          general.quicklook("metadata_titles", $(this).data('model-id'));
        });
      })
    })
  },
  
  metadata_season_cast() {
    $.each($('#season_cast_crew_form .ui.metadata-season-cast-search.dropdown'), function(_i, item) { 
      $(item).dropdown({
        minCharacters: 2,
        apiSettings: {
          cache: false,
          url: '/metadata_seasons/casts?q={/query}'
        },
        templates: {
          menu: dropdownTemplates.metadata_season_cast_search
        },
        onChange(_value, text, choice) {
          $(item).parent().parent().parent().find(".ui.msc-First-Name").val(choice.data("firstname"));
          $(item).parent().parent().parent().find(".ui.msc-Middle-Name").val(choice.data("middlename"));
          return $(item).parent().parent().parent().find(".ui.msc-Last-Name").val(text);
        }
      }).dropdown('set selected', $(item).val())
    })
  },

  title_autocompletion(node) {
    let search_field = $(node).find(".search.metadata_season_episodes")
    search_field.search("destroy")

    const url = '/metadata_seasons/episodes?q={/query}';
    return search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
        data: {
          c_ID: $('.ui.ms-c-ID.dropdown :selected').val()
        },
      },
      templates: {
        standard: metadata_seasons.metadata_season_episode_search
      },
      onResultsAdd(_html) {
        setTimeout(function() {
          Array.from($(search_field).find('.remote.metadata_title.card')).map((card) => general.fetch_view(card))
        }, 100)
        return true
      },
      onSelect(result, _response) {
        search_field.find('.add_nested_fields_link').trigger('click', [{
          metadata_title: result
        }])

        if($(".placeholder.segment.relations").length > 0) {
          $(".placeholder.segment.relations").remove()
        }

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    })
  },

  metadata_season_episode_search(response) {
    let html = '';
    $.each(response.results, function(index, option) {
      console.log(option)
      html += '<div class="result" style="padding: 0;"><div class="content">';
      html += `<div class="remote metadata_title card" data-model-name="metadata_titles" data-model-id="${option.value}" data-type="list" data-fields="no-links" style="margin-top: 10px;"><div class="card list item" style="height: 62px; padding: 0.4em 1em;"><div class="ui placeholder"><div class="line"></div><div class="line"></div></div></div></div>`
      html += '</div></div>';
    });
    return html;
  },
  
  metadata_season_comment() {
    $.each($('#season_comment_form .ui.metadata-season-comment-mail'), function(_i, item) { 
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/metadata_seasons/comments?q={/query}',
          data: {
            c_ID: $('.ui.ms-c-ID.dropdown :selected').val()
          },
          beforeSend(settings) {
            settings.data.c_ID = $('.ui.ms-c-ID.dropdown :selected').val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.metadata_season_comment_search
        }
      }).dropdown('set selected', $(item).val())
    })
  },
  
  init_dropdowns() {
    $("#metadata_seasons .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});

    metadata.contact_dropdown()
    metadata.language_dropdown()
    metadata.territory_dropdown()
  },

  export_metadata_modal() {
    if($(".export_metadata").length > 0){
      $(".export_metadata.button").off("click")
      $(".export_metadata.button").on('click', function () {
        $.ajax({
          url: "/metadata_seasons/" + $(".metadata_season_id").val() + "/metadata_export_modal",
        }).done(function(data) {
          $("body").append(data);
          $('.export.metadata.season.modal').modal({
            autofocus: false,
            onShow() {
              $(".export.metadata.modal").find(".ui.dropdown").dropdown()
              $(".export.metadata.modal").find(".button.export.imagion.template").on('click', function() {
                $('.export.metadata.season.modal .content .message').remove()
                $.ajax({
                  url: "/metadata_seasons/" + $(".metadata_season_id").val() + "/metadata_export",
                  type: "POST",
                  data: {
                    tc_id: $(".export.metadata.modal").find(".export.territory").dropdown("get value"),
                    lc_id: $(".export.metadata.modal").find(".export.language").dropdown("get value"),
                    email: $(".export.metadata.modal").find(".export.email").val(),
                  }
                }).done(_data => $('.export.metadata.season.modal .content').prepend("<div class='ui visible info message'>Metadata export started.</div>")).fail(function(message) {
                  $('.export.metadata.season.modal .content').prepend("<div class='ui visible error message'>" + message.responseJSON.error +"</div>")
                  console.log("error")
                  console.log(message)
                } );
              })
            }, 
            onHidden() {
              $('.export.metadata.season.modal').remove();
            }
          }).modal("show");
  
        }).fail(() => console.log("error"));
      })
    }
  
  },

  init_index_body() {
    general.index_quicklook("metadata_seasons")
  },
}

window.metadata_seasons = metadata_seasons;
