import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [
    "startTc",
    "endTc",
    "destinationTc",
    "differenceLabel",
    "calculationBaseDropdown",
    "outputFramerateDropdown",
    "outputTimecodeFormatDropdown",
    "createAssetCheckbox",
    "importIntoAwaitedAssetCheckbox",
    "importIntoAwaitedComponent",
    "assetSpecTypeDropdown",
    "syncGroupTc",
    "recut",
    "syncMasterSearch",
  ]

  static outlets = [
    "remote-dropdown",
    "workflow--components--awaited-asset",
    "workflow--main",
  ]

  static values = {
    paramsId: String,
    assetId: String,
    frameRate: String,
    timecodeFormat: String,
    durationTc: { type: String, default: null },
    activeContentDurationTc: { type: String, default: null },
    calculationBasis: { type: String, default: "file-duration"}
  }

  connect() {
    this.check_framerate()
    this.recalculateDifference()
  }

  get workflow_main_controller() { 
    return this.workflowMainOutlets.find(o => o.element.contains(this.element))
  }

  uncheckImportIntoAwaitedAssetCheckbox() {
    $(this.importIntoAwaitedAssetCheckboxTargets).checkbox("uncheck")
  }

  recalculateEndTc() {
    let start_tc = this.startTcTarget.value
    let end_tc = "23:59:59:00"

    switch(this.calculationBasisValue) {
      case "file-duration":
        if (this.hasDurationTcValue && this.durationTcValue != "") {
          end_tc = Timecode.add_timecodes(this.durationTcValue, start_tc, this.frameRateValue, this.timecodeFormatValue)
        }
        break
      case "active-content":
        if (this.hasActiveContentDurationTcValue && this.activeContentDurationTcValue != "") {
          end_tc = Timecode.add_timecodes(this.activeContentDurationTcValue, start_tc, this.frameRateValue, this.timecodeFormatValue)
        }
        break
      case "custom":
        return
        break
    }

    if (this.hasEndTcTarget) {
      this.endTcTarget.value = end_tc
    }
    this.recalculateDifference()
  }

  recalculateDifference() {
    if (this.hasStartTcTarget && this.hasDestinationTcTarget && this.hasDifferenceLabelTarget) {
      let difference_tc = Timecode.subtract_timecodes(this.destinationTcTarget.value, this.startTcTarget.value, this.frameRateValue, this.timecodeFormatValue)
      this.differenceLabelTarget.textContent = difference_tc
    }
  }

  check_framerate() {
    const target = $(this.element)
    var framerate = target.find('.framerate.config.selection').dropdown('get value')
    if (framerate == 9) {
      target.find('.tc.fields').removeClass("two").addClass("three")
      target.find('.timecode_format.field').show()
    } else {
      target.find('.tc.fields').addClass("two").removeClass("three")
      target.find('.timecode_format.field').hide()
    }
  }

  async setOnChange() {
    const c = this
    const target = $(c.element)

    var itt_message = $(`
    <div class="ui timecode visible warning message" data-controller="message">
      <i class="close icon"></i>
      <div class="header">
        Timecode deviation detected!
      </div>
      <p>The Timecode offset differs from the sync slave start timecode.<br>Please check whether this is intened for the selected specs with an <b>ITT</b> output format.</p>
    </div>
    `).hide()

    var specs = $(this.assetSpecTypeDropdownTargets).dropdown('get value')
    
    try {
      const response = await get(`/asset_spec_types.json`, { 
        query: new URLSearchParams(specs.map(e => ["could_be_itt[]", e])) 
      })
      if (response.ok) {
        const data = await response.json
        if (data.result) {
          const sync_tc = this.syncGroupTcTarget.value
          const current_tc = this.startTcTarget.value
          const plusminus = this.recutTarget.value

          if (sync_tc != current_tc || (sync_tc == current_tc && plusminus > 0)) {
            this.startTcTarget.closest('.field').classList.add("warning")
            this.workflow_main_controller?.displayMessage("Timecode deviation detected!", "The Timecode offset differs from the sync slave start timecode.<br>Please check whether this is intened for the selected specs with an <b>ITT</b> output format.", "ui icon closable warning message", "exclamation triangle icon", "itt_timecode_warning")
          } else {
            this.startTcTarget.closest('.field').classList.remove("warning")
            this.workflow_main_controller?.removeMessages("itt_timecode_warning")
          }
        }
      } else {
        this.startTcTarget.closest('.field').classList.remove("warning")
        this.workflow_main_controller?.removeMessages("itt_timecode_warning")
      }
    } catch(error) {
    }

    c.check_framerate()
    workflow.update_whparams(target)
    c.validateAwaitedAsset()
  }

  get asset_spec_type_dropdown() {
    return this.remoteDropdownOutlets.find((o) => o.element == this.assetSpecTypeDropdownTarget)
  }

  distributionChannelTypesChanged({ detail: { value } }) {
    this.asset_spec_type_dropdown.setQueryParam("search_distribution_channel_types[]", value.split(","))
  }

  async changeMasterAsset({ detail: { result: asset }}) {
    const card_html = `
      <turbo-frame id="sync_master_card_${asset.a_ID}" src="/assets/${asset.a_ID}/card?include_link=false&amp;turbo_frame_id=sync_master_card_${asset.a_ID}&amp;type=full">
        <div class="ui active small centered inline loader"></div>
      </turbo-frame>
    `
    await this.updateDefaults(asset)
    $(this.element).find(".selected-sync-master.details").html(card_html)
  }

  async updateDefaults(asset) {
    try {
      const response = await get(`/assets/${this.assetIdValue}/sync_group_info_for_subtitle?master_asset_id=${asset.a_ID}`)
      if (response.ok) {
        const data = await response.json
        this.frameRateValue = data.frame_rate
        this.timecodeFormatValue = data.timecode_format
        this.durationTcValue = data.duration_timecode
        this.activeContentDurationTcValue = data.active_content_duration_timecode
        $(this.syncGroupTcTargets).val(data.sync_group_start_timecode)
        this.startTcTarget.value = data.sync_group_start_timecode || data.file_start_timecode || "00:00:00:00"
        $(this.outputFramerateDropdownTargets).dropdown("set exactly", data.frame_rate_id)
        $(this.outputTimecodeFormatDropdownTargets).dropdown("set exactly", data.timecode_format)
      }
      this.recalculateEndTc()
    } catch(error) {
      console.error(error)
    }
  }

  changeEndTcCalculationBasis({ detail: { value } }) {
    this.calculationBasisValue = value
    this.recalculateEndTc()
  }

  setCustomCalculationBase() {
    this.calculationBasisValue = "custom"
    if (this.hasCalculationBaseDropdownTarget) {
      $(this.calculationBaseDropdownTarget).dropdown("set selected", "custom", true)
    }
  }

  validateAwaitedAsset() {
    const asset_spec_type_dropdown_field = $(this.assetSpecTypeDropdownTarget).closest(".field")
    asset_spec_type_dropdown_field.find(".ui.red.label").remove()
    if (this.assetSpecTypeDropdownTarget.selectedOptions.length > 1 && $(this.importIntoAwaitedAssetCheckboxTarget).checkbox("is checked")) {
      forms.disable("validate_awaited_asset")
      asset_spec_type_dropdown_field.append("<div class='ui mini red label'>Only one Spec possible for a Awaited Asset</div>")
    } else {
      forms.enable("validate_awaited_asset")
    }
  }

  enableImportIntoAwaited() {
    $(this.importIntoAwaitedComponentTargets).show()
    $(this.createAssetCheckboxTargets).checkbox("uncheck")
  }

  disableImportIntoAwaited() {
    $(this.importIntoAwaitedComponentTargets).hide()
    this.workflowComponentsAwaitedAssetOutlets.forEach(o => o.clear())
  }
}