import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'visibility',
  ]

  show() {
    $(this.visibilityTargets).show()
  }

  hide() {
    $(this.visibilityTargets).hide()
  }

  toggle() {
    $(this.visibilityTargets).toggle()
  }
}
