import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "awaitedAssetDropdown"
  ]

  static outlets = [
    "workflow--main",
  ]

  static values = {
    paramsId: String,
  }

  get workflow_main_controller() { 
    return this.workflowMainOutlets.find(o => o.element.contains(this.element))
  }

  get hidden_awaited_asset_id_input() {
    return this.workflow_main_controller?.element?.querySelector("#import_into_awaited")
  }

  connect() {
  }

  assetSearchDropdownInitialized({ detail: { dropdown } }) {
    const awaited_asset_id = $(this.hidden_awaited_asset_id_input).val()?.padStart("")
    if (awaited_asset_id) {
      dropdown.dropdown('queryRemote', '', function() {
        dropdown.dropdown('set exactly', [awaited_asset_id])
      })
    }
  }

  awaitedAssetChanged({ detail: { value } }) {
    $(this.workflow_main_controller.element).find(`#awaited_asset_id_${this.paramsIdValue}`).val(value).trigger('change')
    this.dispatch("changed")
  }
  
  clear() {
    $(this.awaitedAssetDropdownTargets).dropdown("clear")
  }
}