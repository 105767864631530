import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static targets = [
    'importCheckbox',
    'importIntoAwaitedComponent',
    'importIntoAwaitedCheckbox',
    'importIntoAwaitedCheckboxField',
    'titleEditorial',
  ]

  static values = {
    paramsId: String,
  }

  static outlets = [
    'workflow--components--awaited-asset',
    'workflow--main',
  ]

  connect() {
  }

  get target() { return $(this.element.closest('fieldset')) }

  get workflow_main_controller() { 
    return this.workflowMainOutlets.find(o => o.element.contains(this.element))
  }

  enableImportIntoAsset() {
    this.target.find('.content-package-component').hide()
    this.target.find('.content-package.checkbox').checkbox('uncheck')
    this.workflow_main_controller?.showComponent("[data-controller*=workflow--components--title]")
    $(this.importIntoAwaitedCheckboxFieldTargets).show()
  }

  disableImportIntoAsset() {
    this.workflow_main_controller?.hideComponent("[data-controller*=workflow--components--title]")
    $(this.importIntoAwaitedCheckboxTargets).checkbox("uncheck")
    $(this.importIntoAwaitedCheckboxFieldTargets).hide()
  }

  enableImportIntoAwaited() {
    $(this.importIntoAwaitedComponentTargets).show()
    this.workflow_main_controller?.hideComponent("[data-controller*=workflow--components--title]")
  }

  disableImportIntoAwaited() {
    $(this.importIntoAwaitedComponentTargets).hide()
    this.workflow_main_controller?.showComponent("[data-controller*=workflow--components--title]")
    this.workflowComponentsAwaitedAssetOutlets.forEach(o => o.clear())
  }

}
