import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [
    'init',
    'messageContainer',
    'component',
  ]
  static values = {
  }

  connect() {
  }

  initTargetConnected(element) {
    this.finalizeView(element)
  }

  async finalizeView(element) {
    const c = this
    const params_id = element.dataset.paramsId
    const uuid = element.dataset.uuid
    workflow.finalize_view($(this.element), params_id, uuid)
    forms.enable("fetch_view")
    workflow.ensure_default_workflow_order()
    c.setDefaultsForDefaultWorkflow(element)
    c.setWaitingFields(element)
    c.fillAwaitedAsset()
  }

  setDefaultsForDefaultWorkflow(element) {
    if (element.dataset.isDefaultWorkflow === 'true') {
      let default_workflow = $(".default-workflows").find(this.element)
      const workflow_id = $(default_workflow).find(".workflow-job-template").val()
      if (workflow_id === "90") { // Subtitle Generator
        $(default_workflow).find('.asset-spec-type').dropdown('refresh').dropdown('set selected', "11") // JAMES Proxy spec
        $(default_workflow).find('.create-asset').prop('checked', false).trigger('change')
      }
    }
  }

  setWaitingFields(element) {
    const c = this
    if (element.dataset.isArchived === 'true' && !workflow_jobs.retrieve_exemptions().includes(parseInt(element.dataset.wjtId))) {
      $(c.element).find(".bottom.attached .start-workflow.buttons .button").not('.active').trigger('click')
    }
  }

  fillAwaitedAsset() {
    const c = this
    let awaited_asset_id = $('#import_into_awaited')?.val()
    if (typeof awaited_asset_id !== 'undefined' && awaited_asset_id !== "" ) {
      $(c.element).find('.asset-from.checkbox').checkbox('check')
      $(c.element).find('.awaited.asset .checkbox').checkbox('check')
      const dct_ID = $('#delivery_order_distribution_channel_type').val()
      if (typeof dct_ID !== 'undefined' && dct_ID !== "") {
        $('.ui.multiple.dropdown').dropdown('set exactly', [dct_ID])
      }
    }
  }

  createMessage(header, content, class_names, icon_class_names, identifier) {
    const message = document.createElement("div")
    message.dataset.controller = "message"
    class_names = icon_class_names ? [...new Set([...class_names.split(" "), ...["icon"]])].join(" ") : class_names
    message.className = `ui visible message ${class_names}`
    const icon_element = icon_class_names ? `<i class="${icon_class_names}"></i>` : ""
    message.setAttribute("data-identifier", identifier)
    message.innerHTML = `
      <i class="close icon" data-action="click->message#close"></i>
      ${icon_element}
      <div class="content">
        <div class="header">${header}</div>
        <p>${content}</p>
      </div>
    `
    return message
  }

  displayMessage(header, content, class_names, icon_class_names, identifier) {
    if (!this.hasMessageContainerTarget) return
    this.removeMessages(identifier)
    const message = this.createMessage(header, content, class_names, icon_class_names, identifier)
    this.messageContainerTarget.appendChild(message)
  }

  removeMessages(identifier) {
    if (!this.hasMessageContainerTarget) return
    const messages = this.messageContainerTarget.querySelectorAll(`[data-identifier="${identifier}"]`)
    if (messages) messages.forEach(m => m.remove())
  }

  hideComponent(selector) {
    $(this.componentTargets).filter(selector).hide()
  }

  showComponent(selector) {
    $(this.componentTargets).filter(selector).show()
  }
  
}
